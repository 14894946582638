import {
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  PRODUCTS,
  PRODUCT_CODE_AU_GEM,
  PRODUCT_CODE_AFS,
  PRODUCT_CODE_NZ_GEM,
  PRODUCT_DAVID_JONES,
} from '_config';

const defaultMaintenanceMessage = 'We will be back online as soon as possible. Thank you for your patience.';

const productCodes = Object.values(PRODUCTS).flatMap(countryProducts => Object.keys(countryProducts));

const defaultMaintenanceMessages = productCodes.reduce((acc, code) => {
  acc[code] = defaultMaintenanceMessage;
  return acc;
}, {});

// Default values only, updated values are coming from LaunchDarkly
const defaultPremiereRatesAndFees = {
  purchaseAnnualRate: 23.99,
  cashAnnualRate: 24.99,
  annualFee: 99,
  additionalCardHolderFee: 24,
  lateFee: 35.0,
  paperStatementFee: 3.0,
  ausPostHandlingFee: 3.95,
  replacementCardFee: 0.0,
};

// Default values only, updated values are coming from LaunchDarkly
const defaultPrestigeRatesAndFees = {
  purchaseAnnualRate: 23.99,
  cashAnnualRate: 24.99,
  annualFee: 295,
  additionalCardHolderFee: 'Not Applicable',
  lateFee: 35.0,
  paperStatementFee: 3.0,
  ausPostHandlingFee: 3.95,
  replacementCardFee: 0.0,
};

// Default values only, updated values are coming from LaunchDarkly
const defaultAu28DRatesAndFees = {
  purchaseAnnualRate: 27.99,
  cashAnnualRate: 29.99,
  annualFee: 0.0,
  annualReward: 120,
  additionalCardHolderFee: 0.0,
  lateFee: 45.0,
  paperStatementFee: 5,
  ausPostHandlingFee: 3.95,
  onlinePaymentHandlingFee: 1.95,
  replacementCardFee: 0.0,
  cashAdvanceFeePercentagePerTransaction: 3.5,
  cashAdvanceFeeFixed: 4,
  monthlyFee: 0,
};

// Default values only, updated values are coming from LaunchDarkly
const defaultGoRatesAndFees = {
  purchaseAnnualRate: 27.99,
  cashAnnualRate: 29.99,
  expiredPromotionalPurchaseRate: 29.99,
  closingBalance: 10,
  cashAdvanceFeeFixed: 4,
  cashAdvanceFeePercentagePerTransaction: 3.5,
  annualFee: 0.0,
  additionalCardHolderFee: 0.0,
  lateFee: 45.0,
  paperStatementFee: 5,
  ausPostHandlingFee: 3.95,
  onlinePaymentHandlingFee: 1.95,
  balanceTransferringFee: 3,
  replacementCardFee: 0.0,
  monthlyFee: 10.95,
  establishmentFee: 0,
  internationalCashAccessRate: 3,
  internationalCashAccessFee: 3,
  internationalCashAccessAdditionalFee: 4,
  internationalCounterTransactionFee: 5,
  internationalTransactionFee: 3,
};

const defaultAuGemRatesAndFees = {
  purchaseAnnualRate: 27.99,
  cashAnnualRate: 29.99,
  expiredPromotionalPurchaseRate: 29.99,
  maximumPurchase1: 249.99,
  maximumPurchase2: 250.0,
  minimumRepaymentRate: 3,
  adjustedClosingBalance: 25,
  minimumRepaymentBalance: 5,
  closingBalance: 10,
  cashAdvanceFeeFixed: 4,
  cashAdvanceFeePercentagePerTransaction: 3.5,
  internationalTransactionRate: 3,
  annualFee: 0.0,
  additionalCardHolderFee: 0.0,
  lateFee: 45.0,
  paperStatementFee: 5,
  ausPostHandlingFee: 3.95,
  onlinePaymentHandlingFee: 1.95,
  balanceTransferringFee: 3,
  replacementCardFee: 0.0,
  monthlyFee: 10.95,
  interestRate: 0,
};

const defaultAfsRatesAndFees = {
  purchaseAnnualRate: 25.9,
  cashAnnualRate: 25.9,
  cashAdvanceFeeFixed: 4,
  cashAdvanceFeePercentagePerTransaction: 3.5,
  annualFee: 0.0,
  lateFee: 45.0,
  paperStatementFee: 5,
  ausPostHandlingFee: 3.95,
  onlinePaymentHandlingFee: 1.95,
  replacementCardFee: 0.0,
  monthlyFee: 5.95,
  establishmentFee: 0,
  eftposWithDrawalFee: 0.85,
  counterAdvanceWithDrawalFee: 5,
  counterPaymentWithDrawalFee: 1.85,
  expiredPromotionalPurchaseRate: 25.9,
};

export const defaultNzRatesAndFees = {
  lateFee: 15,
  purchaseAnnualRate: 28.99,
  additionalCardHolderFee: 0,
  paperStatementFee: 1,
  replacementCardFee: 5,
  annualFee: 55,
  establishmentFee: 55,
  cashAnnualRate: 29.95,
  nzPostHandlingFee: 2.7,
  internationalTransactionFee: 2,
  atmWithdrawalFeeInternational: 1.5,
  atmWithdrawalFee: 1,
  statementReprintFee: 5,
  cashWithdrawalFee: 2,
};

export const DEFAULT_FEATURES_NZ = {
  promoCode: false,
  saveAndResume: false,
  showSaveAndResumeButton: false, // for A/B test https://di.latitudefinancial.com/jira/browse/NZP-1565
  dynamicCreditLimit: false,
  creditLimit99999: false,
  biometrics: true,
  incomeReplay: false,
  maintenancePage: false,
  nzRatesAndFees: defaultNzRatesAndFees,
  maintenanceMessage: defaultMaintenanceMessages,
  emailVerification: false,
};

export const DEFAULT_FEATURES_DJ = {
  djSaveAndResume: true,
  djDynamicCreditLimit: true,
  djMaintenancePage: false,
  premiereRatesAndFees: defaultPremiereRatesAndFees,
  prestigeRatesAndFees: defaultPrestigeRatesAndFees,
  maintenanceMessage: defaultMaintenanceMessages,
  djEmailVerification: true,
  djAch: true,
};

export const DEFAULT_FEATURES_28D = {
  au28DLaunched: true,
  au28DSaveAndResume: true,
  au28DDynamicCreditLimit: true,
  au28DMaintenancePage: false,
  au28DRatesAndFees: defaultAu28DRatesAndFees,
  maintenanceMessage: defaultMaintenanceMessages,
  au28DEmailVerification: true,
  au28DAch: true,
};

export const DEFAULT_FEATURES_GO = {
  goLaunched: true,
  goSaveAndResume: true,
  goDynamicCreditLimit: true,
  goMaintenancePage: false,
  goRatesAndFees: defaultGoRatesAndFees,
  maintenanceMessage: defaultMaintenanceMessages,
  goEmailVerification: true,
  goAch: true,
};

export const DEFAULT_FEATURES_AU_GEM = {
  auGemLaunched: true,
  auGemSaveAndResume: true,
  auGemDynamicCreditLimit: true,
  auGemMaintenancePage: false,
  auGemRatesAndFees: defaultAuGemRatesAndFees,
  maintenanceMessage: defaultMaintenanceMessages,
  auGemEmailVerification: true,
  auGemAch: true,
};

export const DEFAULT_FEATURES_AFS = {
  afsLaunched: true,
  afsSaveAndResume: true,
  afsDynamicCreditLimit: true,
  afsMaintenancePage: false,
  afsRatesAndFees: defaultAfsRatesAndFees,
  maintenanceMessage: defaultMaintenanceMessages,
  afsEmailVerification: true,
  afsAch: false,
};

export const productCodeToDefaultFeatures = {
  [PRODUCT_CODE_NZ_GEM]: DEFAULT_FEATURES_NZ,
  [PRODUCT_DAVID_JONES]: DEFAULT_FEATURES_DJ,
  [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: DEFAULT_FEATURES_DJ,
  [PRODUCT_CODE_DAVID_JONES_PREMIERE]: DEFAULT_FEATURES_DJ,
  [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: DEFAULT_FEATURES_28D,
  [PRODUCT_CODE_GO]: DEFAULT_FEATURES_GO,
  [PRODUCT_CODE_AU_GEM]: DEFAULT_FEATURES_AU_GEM,
  [PRODUCT_CODE_AFS]: DEFAULT_FEATURES_AFS,
};
