import React from 'react';
import { PRODUCT_CODE_AFS, PRODUCT_CODE_AU_GEM, PRODUCT_CODE_GO, PRODUCT_CODE_TWENTY_EIGHT_DEGREES } from '_config';
import { byCard } from 'utils';
import { GoFeaturesAndBenefits } from './features-modal/GoFeaturesAndBenefits';
import { AuGemFeaturesAndBenefits } from './features-modal/AuGemFeaturesAndBenefits';
import { AfsFeaturesAndBenefits } from './features-modal/AfsFeaturesAndBenefits';
import { Au28DFeaturesAndBenefits } from './features-modal/Au28DFeaturesAndBenefits';

export const FeaturesAndBenefitsModal = ({ isOpen, onClose, getLinkWithAnalytics, trackEventSender }) => {
  const Modal = byCard({
    [PRODUCT_CODE_GO]: GoFeaturesAndBenefits,
    [PRODUCT_CODE_AU_GEM]: AuGemFeaturesAndBenefits,
    [PRODUCT_CODE_AFS]: AfsFeaturesAndBenefits,
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: Au28DFeaturesAndBenefits,
    default: '',
  });
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      getLinkWithAnalytics={getLinkWithAnalytics}
      trackEventSender={trackEventSender}
    />
  );
};
