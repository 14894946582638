import { setSessionStorage, getSessionStorage } from 'utils/sessionStorage';

export const sessionStorageKey = 'config';

export * from './_constants';

export const STEP_DAVID_JONES_PREMIERE = '/davidjonespremiere';
export const STEP_DAVID_JONES_PRESTIGE = '/davidjonesprestige';
export const STEP_TWENTY_EIGHT_DEGREES = '/28degrees';
export const STEP_GO = '/go';
export const STEP_GEM_AU = '/gem';
export const STEP_CREDIT_LINE = '/creditline';
export const STEP_PRODUCT_COMPARISON = '/davidjones-product-comparison';
export const STEP_ABOUT_YOU = '/about-you';
export const STEP_ACCEPTANCE = '/acceptance';
export const STEP_ADDRESS_DETAILS = '/address-details';
export const STEP_BIOMETRICS_ABOUT_YOU = '/biometrics-about-you';
export const STEP_BIOMETRICS_ADDRESS_DETAILS = '/biometrics-address-details';
export const STEP_BIOMETRICS_CANCELLED = '/biometrics-cancelled';
export const STEP_BIOMETRICS_COMPLETE = '/biometrics-complete';
export const STEP_BIOMETRICS_IDENTIFICATION = '/biometrics-identification';
export const STEP_BIOMETRICS_RETURN = '/biometrics-return';
export const STEP_COMMITMENTS = '/commitments';
export const STEP_ADDITIONAL_CARD_HOLDER = '/additional-card-holder';
export const STEP_CONFIRM_CREDIT_LIMIT = '/credit-limit-details';
export const STEP_LOAN_INFORMATION = '/loan-information';
export const STEP_CONFIRM_INCOME = '/confirm-income';
export const STEP_CONTACT_DETAILS = '/contact-details';
export const STEP_CREDIT_LIMIT = '/credit-limit';
export const STEP_DVS_CONSENT = '/dvs-consent';
export const STEP_EIDV = '/check-your-identity';
export const STEP_AUS_POST = '/aus-post-identity';
export const STEP_VERIFY_INSTORE = '/verify-identity-instore';
export const STEP_EXPENSES = '/expenses';
export const STEP_FINANCIALS = '/financials';
export const STEP_IDENTIFICATION = '/identification';
export const STEP_INCOME = '/income';
export const STEP_LANDING = '/';
export const STEP_LATITUDE_ID_INTRODUCTION = '/latitude-id-introduction';
export const STEP_EMAIL_VERIFICATION = '/verify-email';
export const STEP_LATITUDE_ID = '/latitude-id';
export const STEP_OUTCOME = '/outcome';
export const STEP_OUTCOME_FIN_TABLE = '/outcome-fin-table';
export const STEP_OUTCOME_TIMEOUT = '/outcome-timeout';
export const STEP_PASSWORD = '/password';
export const STEP_PROCESSING = '/processing';
export const STEP_PROCESSING_ACCEPTANCE = '/processing-acceptance';
export const STEP_PROCESSING_BIOMETRICS = '/processing-biometrics';
export const STEP_PROCESSING_IDV = '/processing-IDV';
export const STEP_PROCESSING_PENDING_POI = '/processing-pending-poi';
export const STEP_PROCESSING_POI = '/processing-poi';
export const STEP_PROCESSING_LOAN_INFORMATION = '/processing-loan-information';
export const STEP_RESUME_ACCEPTANCE = '/resume-acceptance';
export const STEP_RESUME_APPLICATION_EXPIRED = '/resume-application-expired';
export const STEP_RESUME_SAVED = '/saved';
export const STEP_RESUME_YOUR_APPLICATION = '/resume-your-application';
export const STEP_SAVED = '/saved';
export const STEP_VERIFY = '/verify';
export const STEP_VERIFY_DOCUMENT_UPLOAD = '/verify-document-upload';
export const STEP_VERIFY_FICONNECT = '/verify-ficonnect';
export const STEP_VERIFY_IDENTITY = '/verify-identity';
export const STEP_VERIFY_INCOME = '/verify-income';
export const STEP_VERIFY_TO_RESUME = '/verify-to-resume';

export const COUNTRY_CODE_AU = 'AU';
export const COUNTRY_CODE_NZ = 'NZ';

export const PRODUCT_CODE_NZ_GEM = 'CCNZGEM';
export const PRODUCT_CODE_DAVID_JONES_PRESTIGE = 'CCAUDJG';
export const PRODUCT_CODE_DAVID_JONES_PREMIERE = 'CCAUDJM';
export const PRODUCT_CODE_TWENTY_EIGHT_DEGREES = 'CCAU28D';
export const PRODUCT_CODE_GO = 'CCAUGOM';
export const PRODUCT_CODE_AU_GEM = 'CCAUGEM';
export const PRODUCT_CODE_AFS = 'CCAUAFS'; // AU Apple Financial Service aka CreditLine credit card

export const PRODUCT_DAVID_JONES = 'DavidJones';

export const DAVID_JONES_FEATURE_PREFIX = 'dj';

export const CREDIT_CARD_TYPE_MASTER = 'master';
export const CREDIT_CARD_TYPE_VISA = 'visa';
export const CREDIT_CARD_TYPE_PLAIN = 'plain';

export const CHANNEL_INSTORE = 'INSTORE';
export const CHANNEL_EXTERNAL_QR = 'EXTERNAL_QR';
export const CHANNEL_DIRECT = 'DIRECT';
export const CHANNEL_ONLINE = 'ONLINE';
export const CHANNEL_APPLY_AND_SHOP = 'APPLY_AND_SHOP';

export const DATALAYER_CHANNEL_INSTORE = 'Instore';
export const DATALAYER_CHANNEL_EXTERNAL_QR = 'External QR';
export const DATALAYER_CHANNEL_APPLY_AND_BUY = 'Apply and Buy';
export const DATALAYER_CHANNEL_DIRECT = 'Direct';

export const PRODUCTS = {
  [COUNTRY_CODE_AU]: {
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: {
      name: 'David Jones Premiere',
      landingPath: STEP_DAVID_JONES_PREMIERE,
      docUploadCardType: 'dj',
      featureToggleKeyPrefix: DAVID_JONES_FEATURE_PREFIX,
      ratesAndFeesFeatureToggleKey: 'premiereRatesAndFees',
      creditCardType: CREDIT_CARD_TYPE_MASTER,
      defaultApplyAndBuy: {
        merchantId: '000421316',
        shortCode: '299',
        solCode: 'HD2',
      },
      datalayer: {
        siteSection: 'dj',
      },
      urls: {
        conditionsOfUse: 'https://assets.latitudefinancial.com/legals/conditions-of-use/davidjones-au/cou.pdf',
      },
    },
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: {
      name: 'David Jones Prestige',
      landingPath: STEP_DAVID_JONES_PRESTIGE,
      docUploadCardType: 'dj',
      featureToggleKeyPrefix: DAVID_JONES_FEATURE_PREFIX,
      ratesAndFeesFeatureToggleKey: 'prestigeRatesAndFees',
      creditCardType: CREDIT_CARD_TYPE_MASTER,
      defaultApplyAndBuy: {
        merchantId: '000421316',
        shortCode: '299',
        solCode: 'HD3',
        channel: CHANNEL_DIRECT,
      },
      datalayer: {
        siteSection: 'dj',
      },
      urls: {
        conditionsOfUse: 'https://assets.latitudefinancial.com/legals/conditions-of-use/davidjones-au/cou.pdf',
      },
    },
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: {
      name: 'Latitude 28° Global Platinum Mastercard',
      landingPath: STEP_TWENTY_EIGHT_DEGREES,
      docUploadCardType: '28degreescard',
      featureToggleKeyPrefix: 'au28D',
      creditCardType: CREDIT_CARD_TYPE_MASTER,
      defaultApplyAndBuy: {
        shortCode: '666',
        solCode: 'BE5',
        merchantId: '000000000',
        channel: CHANNEL_DIRECT,
      },
      datalayer: {
        siteSection: '28degrees',
      },
      urls: {
        conditionsOfUse: 'https://assets.latitudefinancial.com/legals/conditions-of-use/28degrees-au/cou.pdf',
      },
    },
    [PRODUCT_CODE_GO]: {
      name: 'Latitude GO Mastercard',
      landingPath: STEP_GO,
      docUploadCardType: 'go',
      featureToggleKeyPrefix: 'go',
      creditCardType: CREDIT_CARD_TYPE_MASTER,
      defaultApplyAndBuy: {
        shortCode: '999',
        solCode: 'ND7',
        merchantId: '4567489',
        channel: CHANNEL_DIRECT,
        storeFinderUrl: 'https://stores.harveynorman.com.au/',
      },
      datalayer: {
        siteSection: 'go',
      },
      urls: {
        conditionsOfUse: 'https://assets.latitudefinancial.com/legals/conditions-of-use/gomc-au/cou.pdf',
      },
    },
    [PRODUCT_CODE_AU_GEM]: {
      name: 'Latitude Gem Visa',
      landingPath: STEP_GEM_AU,
      docUploadCardType: 'gemvisaau',
      featureToggleKeyPrefix: 'auGem',
      creditCardType: CREDIT_CARD_TYPE_VISA,
      defaultApplyAndBuy: {
        shortCode: '999',
        solCode: 'ED1',
        merchantId: '4567489',
        channel: CHANNEL_DIRECT,
        storeFinderUrl: 'https://www.latitudefinancial.com.au/interest-free/find-a-store/',
      },
      datalayer: {
        siteSection: 'gem',
      },
      urls: {
        conditionsOfUse: 'https://assets.latitudefinancial.com/legals/conditions-of-use/gemvisa-au/cou.pdf',
      },
    },
    [PRODUCT_CODE_AFS]: {
      name: 'CreditLine',
      landingPath: STEP_CREDIT_LINE,
      docUploadCardType: 'creditline',
      featureToggleKeyPrefix: 'afs',
      creditCardType: CREDIT_CARD_TYPE_PLAIN,
      defaultApplyAndBuy: {
        shortCode: '489',
        solCode: 'KB1',
        merchantId: '700021',
        channel: CHANNEL_ONLINE,
        storeFinderUrl: 'https://www.apple.com/au/retail/',
      },
      datalayer: {
        siteSection: 'afs-creditline',
      },
      urls: {
        conditionsOfUse: 'https://assets.latitudefinancial.com/legals/conditions-of-use/creditline-afs/cou.pdf',
      },
    },
  },
  [COUNTRY_CODE_NZ]: {
    [PRODUCT_CODE_NZ_GEM]: {
      name: 'Gem',
      landingPath: STEP_LANDING,
      docUploadCardType: 'gemvisanz',
      featureToggleKeyPrefix: null, // TODO: make NZ feature toggles with prefix
      creditCardType: CREDIT_CARD_TYPE_VISA,
      defaultApplyAndBuy: {
        merchantId: '4567489',
        shortCode: '999',
        solCode: 'FD1',
        channel: CHANNEL_DIRECT,
      },
      datalayer: {
        siteSection: 'gemnz',
      },
    },
  },
};

const configSingleton = {};

export const LANDING_PAGE_STEPS = Object.keys(PRODUCTS)
  .map(countryCode => PRODUCTS[countryCode])
  .reduce((acc, countryProducts) => {
    return [...acc, ...Object.keys(countryProducts).map(productCode => countryProducts[productCode].landingPath)];
  }, []);

export const setConfig = ({ productCode, countryCode: countryCodeInput }) => {
  const countryCode = countryCodeInput || lookupCountryCode(productCode);

  let importCountryConfig = null;
  switch (countryCode) {
    case COUNTRY_CODE_NZ:
      importCountryConfig = () => require('./nz');
      break;
    case COUNTRY_CODE_AU:
      importCountryConfig = () => require('./au');
      break;
    default:
      throw new Error(`Unexpected country code: ${countryCode}`);
  }

  const countryConfig = importCountryConfig();
  Object.assign(configSingleton, {
    countryCode,
    productCode,
    productName: countryCode && productCode ? PRODUCTS[countryCode][productCode].name : null,
    phoneNumbers: productCode && countryConfig?.getPhoneNumbers(productCode),
    ...countryConfig,
  });

  setSessionStorage(sessionStorageKey, {
    countryCode,
    productCode,
  });

  return configSingleton;
};

const initConfig = () => {
  switch (window.location.pathname) {
    case STEP_DAVID_JONES_PRESTIGE:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
        productCode: PRODUCT_CODE_DAVID_JONES_PRESTIGE,
      });
    case STEP_DAVID_JONES_PREMIERE:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
        productCode: PRODUCT_CODE_DAVID_JONES_PREMIERE,
      });
    case STEP_PRODUCT_COMPARISON:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
      });
    case STEP_TWENTY_EIGHT_DEGREES:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
        productCode: PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
      });
    case STEP_GO:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
        productCode: PRODUCT_CODE_GO,
      });
    case STEP_GEM_AU:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
        productCode: PRODUCT_CODE_AU_GEM,
      });
    case STEP_CREDIT_LINE:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
        productCode: PRODUCT_CODE_AFS,
      });
    case STEP_OUTCOME_FIN_TABLE:
      return setConfig({
        countryCode: COUNTRY_CODE_AU,
      });
    case STEP_RESUME_YOUR_APPLICATION: {
      const queryString = window.location.search;
      const queries = new URLSearchParams(queryString);
      const cardType = queries.get('cardType');
      const productCode = queries.get('productCode');
      if (!productCode && cardType) {
        return setConfig({ countryCode: cardType === 'DJ' ? COUNTRY_CODE_AU : COUNTRY_CODE_NZ });
      }
      return setConfig({
        countryCode: COUNTRY_CODE_NZ,
        productCode: PRODUCT_CODE_NZ_GEM,
      });
    }
    case STEP_LANDING:
      return setConfig({
        countryCode: COUNTRY_CODE_NZ,
        productCode: PRODUCT_CODE_NZ_GEM,
      });
    default:
      if (getSessionStorage(sessionStorageKey)) {
        // This is to prevent when user hits refresh and then config always get reset to default NZ settings
        const { countryCode, productCode } = getSessionStorage(sessionStorageKey);
        return setConfig({
          countryCode,
          productCode,
        });
      }

      return setConfig({
        countryCode: COUNTRY_CODE_NZ,
        productCode: PRODUCT_CODE_NZ_GEM,
      });
  }
};

export const config = Object.keys(configSingleton).length ? configSingleton : initConfig();

export function lookupCountryCode(productCode) {
  const matchedPair = Object.entries(PRODUCTS).find(([country, products]) =>
    Object.keys(products).includes(productCode),
  );

  if (!matchedPair) {
    throw new Error(`Unexpected product code: ${productCode}`);
  }

  return matchedPair[0];
}

export const stepToFormMap = {
  [STEP_LANDING]: 'overview',
  [STEP_CONTACT_DETAILS]: 'contactDetails',
  [STEP_RESUME_YOUR_APPLICATION]: 'contactDetails',
  [STEP_VERIFY_TO_RESUME]: 'verification',
  [STEP_VERIFY]: 'verification',
  [STEP_PASSWORD]: 'password',
  [STEP_ABOUT_YOU]: 'personalDetails',
  [STEP_BIOMETRICS_ABOUT_YOU]: 'biometricsAboutYou',
  [STEP_BIOMETRICS_ADDRESS_DETAILS]: 'biometricsAddressDetails',
  [STEP_ADDRESS_DETAILS]: 'otherDetails',
  [STEP_IDENTIFICATION]: 'extraDetails',
  [STEP_INCOME]: 'employmentDetails',
  [STEP_EXPENSES]: 'expensesDetails',
  [STEP_COMMITMENTS]: 'additionalDebtsDetails',
  [STEP_CREDIT_LIMIT]: 'creditLimitDetails',
  [STEP_ACCEPTANCE]: 'acceptance',
  [STEP_PRODUCT_COMPARISON]: 'comparison',
  [STEP_LANDING]: 'overview',
  [STEP_DAVID_JONES_PRESTIGE]: 'overview',
  [STEP_DAVID_JONES_PREMIERE]: 'overview',
  [STEP_TWENTY_EIGHT_DEGREES]: 'overview',
  [STEP_GO]: 'overview',
  [STEP_GEM_AU]: 'overview',
  [STEP_CREDIT_LINE]: 'overview',
  [STEP_VERIFY_DOCUMENT_UPLOAD]: 'employmentDetails',
};

export const formToStepMap = Object.keys(stepToFormMap).reduce(
  (acc, key) => ({
    ...acc,
    [stepToFormMap[key]]: key,
  }),
  {},
);

export const downSellCreditLimit = () => {
  if (config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE) {
    return 6000;
  }
  // for PRODUCT_CODE_NZ_GEM and PRODUCT_CODE_DAVID_JONES_PREMIERE, the minimum credit limit is 1000
  return 1000;
};

export const getMinAcceptedCreditLimit = () => {
  switch (config.productCode) {
    case PRODUCT_CODE_NZ_GEM:
    case PRODUCT_CODE_DAVID_JONES_PREMIERE:
    case PRODUCT_CODE_TWENTY_EIGHT_DEGREES:
      return 1000;
    case PRODUCT_CODE_DAVID_JONES_PRESTIGE:
      return 6000;
    default:
      return 1000;
  }
};
