/* eslint-disable consistent-return */
import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutPage, LayoutContent, FormRenderer, Form, Errors, WarningModal } from 'components';
import { useStoreValue, APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR } from 'store';
import { config } from '_config';
import { initFormConfig, submitHandler, isAU, shouldShowAch } from 'utils';
import { useEventTracking } from 'react-event-tracker';
import { getDataLayerElements } from 'utils/getDataLayerElements';
import { byCountry } from 'utils/byConfig';
import { useWarning } from '_config/au/additionalDebtsDetails';
import { useSaveAndSubmitApplication } from 'hooks/useSaveAndSubmitApplication';
import { isDCLOn } from 'featureToggles';
import { useFormInitialValues, useSaveApplication, useErrorTrackingAndUpdateStore } from '../../hooks';
import { useSteps } from '../../hooks/useSteps';

const FORM_ID = 'additionalDebtsDetails';
const formConfig = initFormConfig(config.formConfigs[FORM_ID]);

export function AdditionalDebtsDetails() {
  const history = useHistory();
  const [storeState, updateStore] = useStoreValue();
  const { acquisition, ...otherState } = storeState;
  const { trackEvent } = useEventTracking();
  const initialValues = useFormInitialValues(FORM_ID);
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore('Next');
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const onModalClose = useCallback(() => setWarningModalOpen(false), [setWarningModalOpen]);
  const { check, warnings } = useWarning();
  const { nextStep, pathname, isLastDraftStep } = useSteps();
  const submitButtonLabel = byCountry({
    AU: shouldShowAch(storeState) ? 'Next - Additional card holder' : 'Next - Choose credit limit',
    NZ: 'Next',
  });

  const goToNextPage = useCallback(
    newAcquisition => {
      trackEvent({
        event: {
          category: 'application',
          action: isLastDraftStep ? 'application-submitted' : 'application-navigation',
          location: 'commitments',
          label: submitButtonLabel,
        },
        ...getDataLayerElements({
          ...storeState,
          ...(newAcquisition ? { acquisition: newAcquisition } : {}),
        }),
      });
      updateStore({
        activeStep: nextStep,
      });
      history.push(nextStep);
    },
    [updateStore, nextStep, history, trackEvent, isLastDraftStep, submitButtonLabel, storeState],
  );

  const clearErrorAndGoNext = () => {
    updateStore({
      applicationErrors: null,
      applicationSubmitted: true,
    });
    goToNextPage();
  };

  const { saveAndSubmit, loading: loadingForSaveAndSubmit } = useSaveAndSubmitApplication({
    storeState,
    onError: handleErrorCallback,
    onSuccess: clearErrorAndGoNext,
  });

  const { save, loading } = useSaveApplication({
    storeState,
    onError: handleErrorCallback,
  });

  const onSubmit = useCallback(
    ({ values, errors }) => {
      const newAcquisition = {
        ...acquisition,
        additionalDebtsDetails: { ...values },
      };

      const updateStoreFormValues = () => {
        updateStore({
          acquisition: newAcquisition,
        });
      };

      const onNzSubmit = () => {
        updateStoreFormValues();

        if (isLastDraftStep) {
          save(newAcquisition);
        }

        updateStore({
          applicationErrors: null,
        });
        goToNextPage(newAcquisition);
      };

      const onAuSubmit = () => {
        updateStoreFormValues();
        if (shouldShowAch(storeState)) {
          goToNextPage(newAcquisition);
        } else {
          saveAndSubmit(newAcquisition);
        }
      };

      submitHandler({
        submit: byCountry({ AU: onAuSubmit, NZ: onNzSubmit }),
        errors,
      });
    },
    [acquisition, goToNextPage, isLastDraftStep, save, saveAndSubmit, storeState, updateStore],
  );

  const retrySubmit = () => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-submitted',
        location: pathname,
        label: 'Retry',
      },
    });

    onSubmit({ values: storeState.acquisition.additionalDebtsDetails });
  };

  const onClickNext = useCallback(
    ({ values, errors }) => {
      if (Object.keys(errors).length > 0) {
        return;
      }
      if (isAU() && !check(values)) {
        setWarningModalOpen(true);
      } else {
        onSubmit({ values, errors });
      }
    },
    [check, onSubmit],
  );

  if (isDCLOn() && otherState.applicationErrors) {
    const retry = byCountry({
      AU: retrySubmit,
      NZ: [APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR].includes(otherState.applicationErrors.type) ? retrySubmit : null,
    });
    return (
      <LayoutPage>
        <Errors
          applicationRef={otherState.applicationRef}
          retry={retry}
          retrying={byCountry({
            NZ: loading,
            AU: loadingForSaveAndSubmit,
          })}
          isPreSubmission
        />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage noSessionTimeout>
      <Form
        id={FORM_ID}
        initialValues={initialValues}
        onSubmit={onClickNext}
        showSaveDraftButton
        saveDraftOnSubmit={!isLastDraftStep}
        saveDraftOnSessionTimeout
        submitButtonLabel={submitButtonLabel}
      >
        {formData => {
          return (
            <LayoutContent>
              <FormRenderer config={formConfig} formData={formData} />
              <WarningModal
                title="Can you confirm these finances are correct?"
                isOpen={isWarningModalOpen}
                onClose={onModalClose}
                onSubmit={() => onSubmit(formData.state)}
                warnings={warnings}
                editButtonText="Edit Finance"
                confirmButtonText="Confirm and Continue"
              />
            </LayoutContent>
          );
        }}
      </Form>
    </LayoutPage>
  );
}
