import React from 'react';
import { Route, Switch, BrowserRouter as Router, Prompt, useLocation } from 'react-router-dom';
import { Overview } from 'views/overview/Overview';
import { LandingPageDJ } from 'views/landing-page-dj/LandingPageDJ';
import { ContactDetails } from 'views/contact-details/ContactDetails';
import { Verification } from 'views/verification/Verification';
import { VerbalPassword } from 'views/verbal-password';
import { LatitudeId } from 'views/latitudeId';
import { AusPost } from 'views/aus-post/AusPost';
import { VerifyIdentity } from 'views/biometrics/VerifyIdentity';
import { BiometricsComplete } from 'views/biometrics/BiometricsComplete';
import { BiometricsCancelled } from 'views/biometrics/BiometricsCancelled';
import { BiometricsReturn } from 'views/biometrics/BiometricsReturn';
import { PersonalDetails } from 'views/personal-details/PersonalDetails';
import { BiometricsAboutYou } from 'views/biometrics/BiometricsAboutYou';
import { BiometricsIdentification } from 'views/biometrics/BiometricsIdentification';
import { BiometricsAddressDetails } from 'views/biometrics/BiometricsAddressDetails';
import { OtherDetails } from 'views/personal-details/OtherDetails';
import { EmploymentDetails } from 'views/employment/EmploymentDetails';
import { ExpensesDetails } from 'views/employment/ExpensesDetails';
import { AdditionalDebtsDetails } from 'views/employment/AdditionalDebtsDetails';
import { ExtraDetails } from 'views/personal-details/ExtraDetails';
import { CreditLimit } from 'views/credit-limit/CreditLimit';
import { VerifyIncome } from 'views/verify-income/VerifyIncome';
import { VerifyBankStatements } from 'views/verify-income/VerifyBankStatements';
import { ProcessingBiometrics } from 'views/processing/ProcessingBiometrics';
import { ProcessingIDV } from 'views/processing/ProcessingIDV';
import { ProcessingPOI } from 'views/processing/ProcessingPOI';
import { ProcessingSubmission } from 'views/processing/ProcessingSubmission';
import { ConfirmCreditLimit } from 'views/confirm-credit-limit';
import { LoanInformation } from 'views/loan-information';
import { EIDV } from 'views/eIDV';
import { EIDVConsent } from 'views/eidv-consent';
import { ReplayIncome } from 'views/replay-income/ReplayIncome';
import { Acceptance } from 'views/acceptance/Acceptance';
import { ProcessingAcceptance } from 'views/processing/ProcessingAcceptance';
import { Outcome } from 'views/outcome/Outcome';
import { SavedConfirmation } from 'views/save-and-resume/SavedConfirmation';
import { ResumeYourApplication } from 'views/save-and-resume/ResumeYourApplication';
import { LandingPageAU } from 'views/landing-page-au/LandingPageAu';
import { RouteScrollHandler, RouteRedirectHandler } from 'utils';
import { usePageTracker, useRoutePageInfo, useSessionUnload, useFormCache } from 'hooks';
import * as configModule from '_config';
import {
  STEP_BIOMETRICS_COMPLETE,
  STEP_BIOMETRICS_CANCELLED,
  STEP_BIOMETRICS_RETURN,
  STEP_BIOMETRICS_IDENTIFICATION,
  STEP_BIOMETRICS_ADDRESS_DETAILS,
  STEP_LATITUDE_ID,
  STEP_PASSWORD,
  STEP_CONFIRM_INCOME,
  STEP_EIDV,
  STEP_DVS_CONSENT,
  STEP_PROCESSING_IDV,
  STEP_PROCESSING_LOAN_INFORMATION,
  STEP_SAVED,
  STEP_RESUME_YOUR_APPLICATION,
  STEP_CONTACT_DETAILS,
  STEP_RESUME_ACCEPTANCE,
  STEP_RESUME_APPLICATION_EXPIRED,
  STEP_DAVID_JONES_PRESTIGE,
  STEP_DAVID_JONES_PREMIERE,
  STEP_PRODUCT_COMPARISON,
  STEP_OUTCOME_FIN_TABLE,
  STEP_OUTCOME_TIMEOUT,
  STEP_LOAN_INFORMATION,
  STEP_TWENTY_EIGHT_DEGREES,
  STEP_GO,
  STEP_AUS_POST,
  STEP_PROCESSING_BIOMETRICS,
  STEP_VERIFY_INCOME,
  STEP_VERIFY_FICONNECT,
  STEP_EMAIL_VERIFICATION,
  STEP_LATITUDE_ID_INTRODUCTION,
  STEP_ADDITIONAL_CARD_HOLDER,
  STEP_VERIFY,
  STEP_VERIFY_TO_RESUME,
  STEP_VERIFY_IDENTITY,
  STEP_BIOMETRICS_ABOUT_YOU,
  STEP_ABOUT_YOU,
  STEP_ADDRESS_DETAILS,
  STEP_IDENTIFICATION,
  STEP_INCOME,
  STEP_EXPENSES,
  STEP_COMMITMENTS,
  STEP_CREDIT_LIMIT,
  STEP_VERIFY_DOCUMENT_UPLOAD,
  STEP_PROCESSING_POI,
  STEP_PROCESSING,
  STEP_PROCESSING_PENDING_POI,
  STEP_CONFIRM_CREDIT_LIMIT,
  STEP_ACCEPTANCE,
  STEP_PROCESSING_ACCEPTANCE,
  STEP_OUTCOME,
  STEP_GEM_AU,
  STEP_CREDIT_LINE,
  STEP_VERIFY_INSTORE,
} from '_config';
import { ResumeApplicationExpired } from 'views/save-and-resume/ResumeApplicationExpired';
import { Metadata } from 'components';
import { AdditionalCardHolder } from 'views/additional-card-holder/AdditionalCardHolder';
import { OKTA_EMAIL_FACTOR } from 'store';
import { LatitudeIdIntroduction } from 'views/latitude-id-introduction';
import { ProcessingPendingPOI } from './views/processing/ProcessingPendingPOI';
import { VerifyDocumentUpload } from './views/doc-upload/VerifyDocumentUpload';
import { OutcomeTimeout } from './views/outcome/OutcomeTimeout';
import { CompareProducts } from './views/product-comparison/CompareProducts';
import { FinancialTable } from './views/outcome/OutcomeApproved/FinancialTable';
import { ProcessingLoanInformation } from './views/processing/ProcessingLoanInformation';
import { VerifyIdentityInstore } from './views/verify-identity-instore/VerifyIdentityInstore';

const allRoutes = Object.entries(configModule)
  .filter(([key]) => key.startsWith('STEP_'))
  .map(([_, value]) => value);

const getIs404 = () => {
  const { pathname } = window.location;
  return !allRoutes.includes(pathname);
};

const RouteHandlers = () => {
  const { siteSubSection, pageTitle } = useRoutePageInfo();

  const is404 = getIs404();
  usePageTracker(is404 ? '404' : siteSubSection, pageTitle, is404);

  return (
    <>
      <RouteScrollHandler />
      <RouteRedirectHandler />
    </>
  );
};

export function Routes() {
  useSessionUnload();
  const { getSubmitRequired, updateStateFlag } = useFormCache();

  const getUserConfirmation = (message, callback) => {
    const submitRequired = getSubmitRequired();
    updateStateFlag(submitRequired);
    // callback(false) prevents nav ie: if submitRequired = true
    callback(!submitRequired);
  };

  return (
    <Router getUserConfirmation={getUserConfirmation}>
      <Prompt message="" />
      <RouteHandlers />
      <Meta />
      <Switch>
        <Route exact path={STEP_PRODUCT_COMPARISON}>
          <CompareProducts />
        </Route>
        <Route exact path="/">
          <Overview />
        </Route>
        <Route exact path={[STEP_DAVID_JONES_PRESTIGE, STEP_DAVID_JONES_PREMIERE]}>
          <LandingPageDJ />
        </Route>
        <Route exact path={[STEP_TWENTY_EIGHT_DEGREES]}>
          <LandingPageAU />
        </Route>
        <Route exact path={[STEP_GO]}>
          <LandingPageAU />
        </Route>
        <Route exact path={[STEP_GEM_AU]}>
          <LandingPageAU />
        </Route>
        <Route exact path={[STEP_CREDIT_LINE]}>
          <LandingPageAU />
        </Route>

        <Route path={STEP_CONTACT_DETAILS}>
          <ContactDetails />
        </Route>
        <Route path={STEP_RESUME_YOUR_APPLICATION}>
          <ResumeYourApplication />
        </Route>
        <Route path={STEP_RESUME_ACCEPTANCE}>
          <ResumeYourApplication />
        </Route>
        <Route path={STEP_VERIFY}>
          <Verification />
        </Route>
        <Route path={STEP_VERIFY_TO_RESUME}>
          <Verification />
        </Route>
        <Route path={STEP_RESUME_APPLICATION_EXPIRED}>
          <ResumeApplicationExpired />
        </Route>
        <Route path={STEP_LATITUDE_ID_INTRODUCTION}>
          <LatitudeIdIntroduction />
        </Route>
        <Route path={STEP_EMAIL_VERIFICATION}>
          <Verification factor={OKTA_EMAIL_FACTOR} />
        </Route>
        <Route path={STEP_LATITUDE_ID}>
          <LatitudeId />
        </Route>
        <Route path={STEP_PASSWORD}>
          <VerbalPassword />
        </Route>
        <Route path={STEP_SAVED}>
          <SavedConfirmation />
        </Route>
        <Route path={STEP_VERIFY_IDENTITY}>
          <VerifyIdentity />
        </Route>
        <Route path={STEP_PROCESSING_BIOMETRICS}>
          <ProcessingBiometrics />
        </Route>
        <Route path={STEP_BIOMETRICS_COMPLETE}>
          <BiometricsComplete />
        </Route>
        <Route path={STEP_BIOMETRICS_CANCELLED}>
          <BiometricsCancelled />
        </Route>
        <Route path={STEP_BIOMETRICS_RETURN}>
          <BiometricsReturn />
        </Route>
        <Route path={STEP_BIOMETRICS_ABOUT_YOU}>
          <BiometricsAboutYou />
        </Route>
        <Route path={STEP_BIOMETRICS_IDENTIFICATION}>
          <BiometricsIdentification />
        </Route>
        <Route path={STEP_BIOMETRICS_ADDRESS_DETAILS}>
          <BiometricsAddressDetails />
        </Route>
        <Route path={STEP_ABOUT_YOU}>
          <PersonalDetails />
        </Route>
        <Route path={STEP_ADDRESS_DETAILS}>
          <OtherDetails />
        </Route>
        <Route path={STEP_IDENTIFICATION}>
          <ExtraDetails />
        </Route>
        <Route path={STEP_INCOME}>
          <EmploymentDetails />
        </Route>
        <Route path={STEP_EXPENSES}>
          <ExpensesDetails />
        </Route>
        <Route path={STEP_COMMITMENTS}>
          <AdditionalDebtsDetails />
        </Route>
        <Route path={STEP_ADDITIONAL_CARD_HOLDER}>
          <AdditionalCardHolder />
        </Route>
        <Route path={STEP_CREDIT_LIMIT}>
          <CreditLimit />
        </Route>
        <Route path={STEP_LOAN_INFORMATION}>
          <LoanInformation />
        </Route>
        <Route path={STEP_PROCESSING_LOAN_INFORMATION}>
          <ProcessingLoanInformation />
        </Route>
        <Route path={STEP_VERIFY_INCOME}>
          <VerifyIncome />
        </Route>
        <Route path={STEP_VERIFY_FICONNECT}>
          <VerifyBankStatements />
        </Route>
        <Route path={STEP_VERIFY_DOCUMENT_UPLOAD}>
          <VerifyDocumentUpload />
        </Route>
        <Route path={STEP_DVS_CONSENT}>
          <EIDVConsent />
        </Route>
        <Route path={STEP_PROCESSING_IDV}>
          <ProcessingIDV />
        </Route>
        <Route path={STEP_AUS_POST}>
          <AusPost />
        </Route>
        <Route path={STEP_VERIFY_INSTORE}>
          <VerifyIdentityInstore />
        </Route>
        <Route path={STEP_PROCESSING_POI}>
          <ProcessingPOI />
        </Route>
        <Route path={STEP_CONFIRM_INCOME}>
          <ReplayIncome />
        </Route>
        <Route path={STEP_PROCESSING}>
          <ProcessingSubmission />
        </Route>
        <Route path={STEP_PROCESSING_PENDING_POI}>
          <ProcessingPendingPOI />
        </Route>
        <Route path={STEP_EIDV}>
          <EIDV />
        </Route>
        <Route path={STEP_CONFIRM_CREDIT_LIMIT}>
          <ConfirmCreditLimit />
        </Route>
        <Route path={STEP_ACCEPTANCE}>
          <Acceptance />
        </Route>
        <Route path={STEP_PROCESSING_ACCEPTANCE}>
          <ProcessingAcceptance />
        </Route>
        <Route path={STEP_OUTCOME}>
          <Outcome />
        </Route>
        <Route path={STEP_OUTCOME_TIMEOUT}>
          <OutcomeTimeout />
        </Route>
        <Route path={STEP_OUTCOME_FIN_TABLE}>
          <FinancialTable />
        </Route>
      </Switch>
    </Router>
  );
}

const specialRouteTabTitles = {
  [STEP_PRODUCT_COMPARISON]: 'David Jones Credit Card Comparison',
};

function Meta() {
  const { pathname } = useLocation();

  const title = specialRouteTabTitles[pathname];

  return <Metadata title={title} />;
}
