import React from 'react';
import { Header as BasisHeader } from 'basis';
import { byCard, byCountry } from 'utils';
import { DavidJonesHeader } from 'components/david-jones/Header';
import { AUCardsHeader } from 'components/au-cards-header/AUCardsHeader';
import {
  PRODUCT_CODE_NZ_GEM,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_AU_GEM,
  PRODUCT_CODE_AFS,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
} from '_config';

export function Header() {
  const ProductHeader = byCard({
    [PRODUCT_CODE_NZ_GEM]: NzHeader,
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: DavidJonesHeader,
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: DavidJonesHeader,
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: AUCardsHeader,
    [PRODUCT_CODE_GO]: AUCardsHeader,
    [PRODUCT_CODE_AU_GEM]: AUCardsHeader,
    [PRODUCT_CODE_AFS]: AUCardsHeader,
    default: byCountry({ NZ: NzHeader, AU: DavidJonesHeader }),
  });

  return <ProductHeader />;
}

function NzHeader() {
  return (
    <BasisHeader>
      <BasisHeader.Logo name="gem" />
    </BasisHeader>
  );
}

Header.ID = 'Header';
Header.HEIGHT_MAP = byCountry({ NZ: BasisHeader.HEIGHT_MAP, AU: DavidJonesHeader.HEIGHT_MAP });
