import { useMemo } from 'react';
import defaults from 'lodash/defaults';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import { isEmpty } from 'lodash';
import { getProductInfo, isNZ } from 'utils/byConfig';
import {
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  config,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_AU_GEM,
  PRODUCTS,
} from '_config';
import * as configModule from '_config';
import { toConstantCase } from 'utils/string';
import {
  HARVEY_NORMAN_IN_STORE_MERCHANTS,
  TIER_3_IN_STORE_MERCHANTS,
  SMP,
  HARVEY_NORMAN_PARAMS,
  IN_STORE_MERCHANT_PARAMS,
  merchants,
  APPLE_QR,
  APPLE_QR_MERCHANT_PARAMS,
  DAVID_JONES_APPLY_AND_SHOP_INSTANTLY_MERCHANT_ID,
  DAVID_JONES_PREMIERE_APPLY_AND_SHOP_INSTANTLY,
  DAVID_JONES_PRESTIGE_APPLY_AND_SHOP_INSTANTLY,
  DAVID_JONES_QR_CODE_MERCHANT_ID,
  DAVID_JONES_PREMIERE_QR_CODE,
  DAVID_JONES_PRESTIGE_QR_CODE,
  DAVID_JONES_QR_CODE,
  DAVID_JONES_PARTNER_WEBSITE_MERCHANT_ID,
  DAVID_JONES_PREMIERE_PARTNER_WEBSITE,
  DAVID_JONES_PRESTIGE_PARTNER_WEBSITE,
} from './params';
import merchantDatabase from './merchantDatabase.json';
import { getSolCode } from './getSolCode';

/**
  @type UseApplyAndBuyQueryParams

  @typedef {() => ApplyAndBuyParams} UseApplyAndBuyQueryParams

  @typedef {
    {
      merchantId?: string
      shortCode?: string
      solCode?: string
      gemId1?: string
      channel?: string
      stream?: string
      merchantName?: string
      merchantSourceCode?: string
      returnUrl?: string

      // Only available for certain credit card types - Go, Gem AU and CreditLine.
      merchantGroupName?: string
      upstreamUrl?: string
      storeFinderUrl?: string
    }
  } ApplyAndBuyParams
*/
export const useApplyAndBuyQueryParams = () => {
  const queryString = window.location.search;
  const applyAndBuy = useMemo(() => {
    /**
      ```ts
      type ExpectedQueryParams = {
        gemid1: string
        merchantID?: string
        origin?: string
        channel?: string
        stream?: string
        shortCode?: string
        solCode?: string
        merchantSourceCode?: string
      }
      ```
     */
    const queryParams = new URLSearchParams(queryString);
    const mappedParams = {
      gemId1: queryParams.get('gemid1'),
      merchantId: queryParams.get('merchantID'),
      channel: getChannel(queryParams.get('channel')),
      stream: queryParams.get('stream'),
      shortCode: queryParams.get('shortCode'),
      solCode: queryParams.get('solCode'),
      merchantSourceCode: queryParams.get('merchantSourceCode'),
      origin: queryParams.get('origin'),
    };

    return calculateApplyAndBuy(mappedParams);
  }, [queryString]);

  return applyAndBuy;
};
export const getDefaultApplyAndBuyParams = () => {
  if (!config.productCode) {
    // in case when resuming DJ application and the product code is not provided in query params.
    return {};
  }

  return getProductInfo().defaultApplyAndBuy;
};

export const calculateApplyAndBuy = mappedParams => {
  if (needMerchantDatabase(mappedParams.merchantId)) {
    const merchant = lookupMerchantViaDb(mappedParams.merchantId);
    const solCode = getSolCode(mappedParams.channel, config.productCode);
    return defaults({ solCode }, merchant, omitEmpty(mappedParams), getDefaultApplyAndBuyParams());
  }

  const merchant = mappedParams.merchantId ? lookupMerchantWithoutDb(mappedParams.merchantId) : null;

  if (
    HARVEY_NORMAN_IN_STORE_MERCHANTS.includes(mappedParams.merchantId) &&
    (mappedParams.shortCode === null || mappedParams.solCode === null || mappedParams.merchantSourceCode === null)
  ) {
    return defaults(merchant, omitEmpty(mappedParams), HARVEY_NORMAN_PARAMS, IN_STORE_MERCHANT_PARAMS);
  }

  if (APPLE_QR === mappedParams.merchantId) {
    return defaults(merchant, omitEmpty(mappedParams), APPLE_QR_MERCHANT_PARAMS);
  }

  if (isNZ() && (mappedParams.origin === SMP || TIER_3_IN_STORE_MERCHANTS.includes(mappedParams.merchantId))) {
    return defaults(merchant, omitEmpty(mappedParams), IN_STORE_MERCHANT_PARAMS, getDefaultApplyAndBuyParams());
  }
  if (
    config.productCode === PRODUCT_CODE_DAVID_JONES_PREMIERE &&
    DAVID_JONES_APPLY_AND_SHOP_INSTANTLY_MERCHANT_ID === mappedParams.merchantId
  ) {
    return defaults(omitEmpty(mappedParams), DAVID_JONES_PREMIERE_APPLY_AND_SHOP_INSTANTLY);
  }
  if (
    config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE &&
    DAVID_JONES_APPLY_AND_SHOP_INSTANTLY_MERCHANT_ID === mappedParams.merchantId
  ) {
    return defaults(omitEmpty(mappedParams), DAVID_JONES_PRESTIGE_APPLY_AND_SHOP_INSTANTLY);
  }
  if (
    config.productCode === PRODUCT_CODE_DAVID_JONES_PREMIERE &&
    DAVID_JONES_QR_CODE_MERCHANT_ID === mappedParams.merchantId
  ) {
    return defaults(omitEmpty(mappedParams), DAVID_JONES_PREMIERE_QR_CODE);
  }
  if (
    config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE &&
    DAVID_JONES_QR_CODE_MERCHANT_ID === mappedParams.merchantId
  ) {
    return defaults(omitEmpty(mappedParams), DAVID_JONES_PRESTIGE_QR_CODE);
  }
  if (DAVID_JONES_QR_CODE_MERCHANT_ID === mappedParams.merchantId) {
    return defaults(omitEmpty(mappedParams), DAVID_JONES_QR_CODE);
  }
  if (
    config.productCode === PRODUCT_CODE_DAVID_JONES_PREMIERE &&
    DAVID_JONES_PARTNER_WEBSITE_MERCHANT_ID === mappedParams.merchantId
  ) {
    return defaults(omitEmpty(mappedParams), DAVID_JONES_PREMIERE_PARTNER_WEBSITE);
  }
  if (
    config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE &&
    DAVID_JONES_PARTNER_WEBSITE_MERCHANT_ID === mappedParams.merchantId
  ) {
    return defaults(merchant, omitEmpty(mappedParams), DAVID_JONES_PRESTIGE_PARTNER_WEBSITE);
  }

  return defaults(merchant, omitEmpty(mappedParams), getDefaultApplyAndBuyParams());
};

/**
 * Omit null and empty string items
 */
const omitEmpty = value => omitBy(value, v => isNull(v) || isEmpty(v));

const lookupMerchantWithoutDb = merchantId => {
  const found = merchants[merchantId];

  if (!found) {
    // eslint-disable-next-line no-console
    console.error(`Merchant not found for merchantId: ${merchantId}; productCode: ${config.productCode}`);
  }

  return found ?? null;
};

function needMerchantDatabase(merchantId) {
  return (
    !!merchantId &&
    [PRODUCT_CODE_GO, PRODUCT_CODE_AU_GEM].includes(config.productCode) &&
    !isDefaultMerchantId(merchantId)
  );
}

function isDefaultMerchantId(merchantId) {
  const defaultMerchantIds = Object.values(PRODUCTS).flatMap(countryProducts =>
    Object.values(countryProducts).flatMap(product => product.defaultApplyAndBuy.merchantId),
  );
  return defaultMerchantIds.includes(merchantId);
}

function lookupMerchantViaDb(merchantId) {
  const found = merchantDatabase.find(m => m.merchantId === merchantId);

  if (!found) {
    // eslint-disable-next-line no-console
    console.error(`Merchant not found for merchantId: ${merchantId}; productCode: ${config.productCode}`);
  }

  return found ?? null;
}

function getChannel(rawChannel) {
  if (!rawChannel) {
    return undefined;
  }
  const channel = toConstantCase(rawChannel);
  const allChannels = Object.entries(configModule)
    .filter(([key]) => key.startsWith('CHANNEL_'))
    .map(([_, value]) => value);
  if (!allChannels.includes(channel)) {
    // eslint-disable-next-line no-console
    console.error(
      `Unexpected channel: ${channel}. Query string: ${window.location.search}. Path name: ${window.location.pathname}`,
    );
    return undefined;
  }
  return channel;
}
