import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { byCard, byCountry } from 'utils';
import {
  config,
  PRODUCT_CODE_NZ_GEM,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_AU_GEM,
  PRODUCT_CODE_AFS,
} from '_config';

// TODO update with real values from marketing
// https://di.latitudefinancial.com/jira/browse/NZP-410
const getNzMeta = () => {
  return {
    title: 'Gem Visa Application',
    description: 'Gem Visa Application',
    siteUrl: '',
    keywords: '',
  };
};

const getAuMeta = () => {
  return {
    title: `${config.productName ? config.productName : 'David Jones'} credit card application`,
    description: `Earn points, enjoy unrivalled service, and access unique benefits and experiences like no other. Unlock the best of the world in one place with a ${
      config.productName ? config.productName : 'David Jones'
    } credit card application.`,
    siteUrl: '',
    keywords: 'credit card, points, David Jones',
  };
};

const getRobotsValue = (noIndex, noFollow) => {
  const output = [];
  noIndex && output.push('noindex');
  noFollow && output.push('nofollow');
  return output.join(', ');
};

const getCanonicalUrl = url => {
  const exp = /https?:\/\//;
  if (!url) {
    if (config.productCode) {
      return byCard({
        [PRODUCT_CODE_NZ_GEM]: process.env.REACT_APP_NZ_APPLY_CANONICAL_URL,
        [PRODUCT_CODE_DAVID_JONES_PREMIERE]: process.env.REACT_APP_DJ_PREMIERE_CANONICAL_URL,
        [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: process.env.REACT_APP_DJ_PRESTIGE_CANONICAL_URL,
        [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: process.env.REACT_APP_TWENTY_EIGHT_DEGREES_CANONICAL_URL,
        [PRODUCT_CODE_GO]: process.env.REACT_APP_GO_CANONICAL_URL,
        default: null,
      });
    }
    return null;
  }

  const isRelative = !exp.test(url);
  return isRelative ? `${getNzMeta.siteUrl}${url}` : url;
};

export const Metadata = ({
  url,
  description: _description,
  keywords: _keywords,
  title: _title,
  noIndex = false,
  noFollow = false,
  children,
}) => {
  const robots = getRobotsValue(noIndex, noFollow);
  const canonicalUrl = getCanonicalUrl(url);

  const getDefaultMeta = byCountry({
    AU: getAuMeta,
    NZ: getNzMeta,
  });

  const defaultMeta = getDefaultMeta();

  const description = _description || defaultMeta.description;
  const keywords = _keywords || defaultMeta.keywords;
  const title = _title || defaultMeta.title;
  const favicon = byCard({
    [PRODUCT_CODE_NZ_GEM]: `${process.env.PUBLIC_URL}/favicon-nz.ico`,
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: `${process.env.PUBLIC_URL}/favicon-dj.ico`,
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: `${process.env.PUBLIC_URL}/favicon-dj.ico`,
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: `${process.env.PUBLIC_URL}/favicon-nz.ico`,
    [PRODUCT_CODE_GO]: `${process.env.PUBLIC_URL}/favicon-nz.ico`,
    [PRODUCT_CODE_AU_GEM]: `${process.env.PUBLIC_URL}/favicon-nz.ico`,
    [PRODUCT_CODE_AFS]: `${process.env.PUBLIC_URL}/favicon-nz.ico`,
    default: byCountry({
      AU: `${process.env.PUBLIC_URL}/favicon-dj.ico`,
      NZ: `${process.env.PUBLIC_URL}/favicon-nz.ico`,
    }),
  });

  return (
    <Helmet>
      <link rel="icon" href={favicon} />
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {robots && <meta name="robots" content={robots} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {title && <meta property="og:site_name" content={title} />}
      {title && <meta property="og:title" content={title} />}
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      <meta property="og:description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {children}
    </Helmet>
  );
};
