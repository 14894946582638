import React, { useState } from 'react';
import { Text, Link, Message } from 'basis';
import { byCard, getProductName } from 'utils';
import {
  APPLY_BTN_LABEL,
  OverviewSources,
  PRODUCT_CODE_AFS,
  PRODUCT_CODE_AU_GEM,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  config,
} from '_config';
import { DEV_FEATURES } from '__dev';
import { ListItem as ChListItem, UnorderedList as ChUnorderedList } from '@chakra-ui/react';
import { ResponsiveList } from './LandingPageAu.styles';

import { VerifierModal } from '../landing-page-dj/VerifierModal';
import { DeclarationModal } from '../landing-page-dj/DeclarationModal';
import { PrivacyNoticeModal } from './PrivacyNoticeModal';

import { StartApplicationButton } from './StartApplicationButton';

const productBenefits = {
  [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: [
    'the ability to make everyday credit card purchases in-store and online at businesses who accept Mastercard and perform other transactions.',
    'pay no international transaction or currency conversion fees on purchases; and',
    'access to a Rewards program to earn redeemable amounts to purchase an e-Gift card on eligible transactions within a statement period.',
  ],
  [PRODUCT_CODE_AFS]: [
    'interest free payment plans at participating retailers, and',
    'the ability to perform other transactions if required.',
  ],
  default: [
    'interest free payment plans at participating retailers, and',
    'the ability to make everyday credit card purchases and / or perform other transactions if required.',
  ],
};

export const ExplainAndStart = ({
  trackEventSender,
  formTealiumLocation,
  theme,
  isAdblockCheckComplete,
  isAdblockerDetected,
  loading,
  formData,
}) => {
  const benefitList = byCard(productBenefits);

  const [verifierModalOpen, setVerifierModalOpen] = useState(false);
  const [declarationModalOpen, setDeclarationModalOpen] = useState(false);
  const [privacyNoticeModalOpen, setPrivacyNoticeModalOpen] = useState(false);
  const keyFactsSheetLink = byCard({
    [PRODUCT_CODE_GO]: OverviewSources.AU.go_key_facts_sheet,
    [PRODUCT_CODE_AFS]: OverviewSources.AU.afs_key_facts_sheet,
    [PRODUCT_CODE_AU_GEM]: OverviewSources.AU.key_facts_sheet,
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: OverviewSources.AU.twenty_eight_degrees_key_facts_sheet,
  });

  const conditionsOfUseLink = byCard({
    [PRODUCT_CODE_GO]: OverviewSources.AU.go_conditions_of_use,
    [PRODUCT_CODE_AFS]: OverviewSources.AU.afs_conditions_of_use,
    [PRODUCT_CODE_AU_GEM]: OverviewSources.AU.gem_conditions_of_use,
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: OverviewSources.AU.twenty_eight_degrees_conditions_of_use,
  });

  const openVerifierModal = () => {
    setVerifierModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: "Verifier's terms of service",
    });
  };

  const closeVerifierModal = () => {
    setVerifierModalOpen(false);
  };

  const openDeclarationModal = () => {
    setDeclarationModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: 'Declaration and Credit Application',
    });
  };

  const closeDeclarationModal = () => {
    setDeclarationModalOpen(false);
  };

  const openPrivacyNoticeModal = () => {
    setPrivacyNoticeModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: formTealiumLocation,
      label: 'Privacy Notice',
    });
  };

  const closePrivacyNoticeModal = () => {
    setPrivacyNoticeModalOpen(false);
  };

  const items = [
    {
      left: 'Please make sure this product is right for you',
      right: (
        <>
          <Text>
            <strong>This Credit Card will give you:</strong>
          </Text>
          <UnorderedList>
            {benefitList.map(benefit => (
              <ListItem>{benefit}</ListItem>
            ))}
          </UnorderedList>
        </>
      ),
    },
    {
      right: (
        <>
          <Text>
            <strong>By starting this application you agree:</strong>
          </Text>
          <UnorderedList>
            <ListItem>
              <Link
                newTab
                href={byCard({
                  [PRODUCT_CODE_AU_GEM]: OverviewSources.AU.latitude_gem_visa.url,
                  [PRODUCT_CODE_GO]: OverviewSources.AU.latitude_go_card.url,
                  [PRODUCT_CODE_AFS]: OverviewSources.AU.latitude_afs_card.url,
                  [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: OverviewSources.AU.latitude_28d_mastercard.url,
                })}
                onClick={() =>
                  trackEventSender({
                    action: 'external-link',
                    label: config.productName,
                    location: formTealiumLocation,
                  })
                }
              >
                {byCard({
                  [PRODUCT_CODE_AU_GEM]: OverviewSources.AU.latitude_gem_visa.label,
                  [PRODUCT_CODE_GO]: OverviewSources.AU.latitude_go_card.label,
                  [PRODUCT_CODE_AFS]: OverviewSources.AU.latitude_afs_card.label,
                  [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: getProductName(),
                  default: '',
                })}
              </Link>{' '}
              meets your key credit card requirements.
            </ListItem>
            <ListItem>
              you have read, understood and agree with the <Link onClick={openPrivacyNoticeModal}>Privacy Notice</Link>,{' '}
              <Link
                newTab
                href={keyFactsSheetLink.url}
                onClick={() =>
                  trackEventSender({
                    location: formTealiumLocation,
                    label: keyFactsSheetLink.label,
                  })
                }
              >
                {keyFactsSheetLink.label}
              </Link>
              ,{' '}
              <Link
                newTab
                href={conditionsOfUseLink.url}
                onClick={() =>
                  trackEventSender({
                    location: formTealiumLocation,
                    label: conditionsOfUseLink.label,
                  })
                }
              >
                {conditionsOfUseLink.label}
              </Link>
              , <Link onClick={openDeclarationModal}>Declaration and Credit Application</Link>. These documents explain
              how your personal information is used, the product's features and terms under which this product operates.
            </ListItem>
            <ListItem>to let us complete a credit check.</ListItem>
            <ListItem>
              to electronically receive your credit contract as set out in the Financial Table and Conditions of Use.
            </ListItem>
            <ListItem>
              to electronically verify your income using a secure process with Verifier (see{' '}
              <Link onClick={openVerifierModal}>Verifier's terms of service</Link>). If your income can't be verified
              this way, we will provide alternatives.
            </ListItem>
          </UnorderedList>
        </>
      ),
    },
    {
      right: (
        <StartApplicationButton
          backgroundColor={theme.newStageButtonColor}
          disabled={isAdblockCheckComplete && isAdblockerDetected && !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP}
          loading={loading}
          theme={theme}
        >
          {APPLY_BTN_LABEL}
        </StartApplicationButton>
      ),
    },
    {
      condition: () => isAdblockCheckComplete && isAdblockerDetected && !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP,
      right: (
        <Message severity="stop" bg="secondary.pink.t30">
          Please turn off your adblocker for the application to work and refresh your browser once it is done.
        </Message>
      ),
    },
  ];

  return (
    <>
      <VerifierModal isOpen={verifierModalOpen} onClose={closeVerifierModal} />
      <DeclarationModal isOpen={declarationModalOpen} onClose={closeDeclarationModal} />
      <PrivacyNoticeModal isOpen={privacyNoticeModalOpen} onClose={closePrivacyNoticeModal} />
      <ResponsiveList list={items} formData={formData} keyPrefix="right-product-for-you" />
    </>
  );
};

function ListItem({ children }) {
  return (
    <ChListItem>
      <Text>{children}</Text>
    </ChListItem>
  );
}

function UnorderedList({ children }) {
  return (
    <ChUnorderedList marginStart="-1.6em" marginInlineStart="-1.6em" fontSize="0.8em">
      {children}
    </ChUnorderedList>
  );
}
